:root {
  font-size: 17pt;
  font-family: 'Sahitya', serif;
}

body {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  .container {
    min-height: 100vh;
    max-width: 1000px;
    width: 100vw;
    position: relative;
    z-index: 0;

    * {
      z-index: 2;
    }

    img,
    canvas {
      max-width: 100%;
      object-fit: cover;
      margin: auto;
      display: block;

      &.comic {
        position: absolute;
        z-index: 1;
        width: 500px;
        display: inline-block;

        &#comic-add {
          right: 0;

          height: 400px;
          object-position: top;
        }
        &#comic-solved {
          height: 300px;
          text-align: center;
          object-position: top;
          position: static;
        }

        &#lerp-comic {
          height: 450px;
        }
      }
    }
  }

  ul {
    list-style: none;

    li::before {
      content: '-\0000a0\0000a0\0000a0';
    }
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img.sampling-img {
    position: absolute;
    width: 45% !important;
    right: 0;
  }
  img.sampling-img-large {
    width: 70%;
  }

  #python-add {
    width: 300px;
  }

  #code-line pre {
    width: 50%;
  }

  .wrap-right {
    width: 50%;
    right: 0;
  }

  #circle-img {
    width: 110%;
    height: 500px;
  }

  .no-border {
    border: none;
  }

  p {
    margin: 3rem 1rem;
    text-align: justify;
  }

  .large-break {
    height: 5rem;
  }

  .no-mg-bt {
    margin-bottom: 0;
  }
  .no-mg-tp {
    margin-top: 0;
  }
  .no-mg-lf {
    margin-left: 0;
  }
  .no-mg-rg {
    margin-left: 0;
  }

  .floating {
    margin: 2rem 5rem;
  }

  .no-brk {
    white-space: nowrap;
  }

  .sketched {
    background: rgb(40, 44, 52);
    color: whitesmoke;
    border: rgb(40, 44, 52) solid 10px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius: 15px 255px;
  }

  iframe {
    border-radius: 20px;
    border: 5px solid #222;
    padding: 10px;
  }

  .compare {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 45%;
    }

    .comic {
      position: static !important;
    }
  }

  .animation {
    position: relative;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .author {
    display: flex;
    justify-content: space-around;
    align-items: center;
    h3 {
      font-family: 'MonteCarlo', cursive;
    }
    .spacer {
      width: 50%;
      height: 3px;
      background-color: #000;
    }

    button,
    a {
      background-color: #fff;
      border: none;
      color: black;
      text-decoration: none;
      display: inline-block;
      font-size: 30px;
      padding: 10px;
      cursor: pointer;
    }
  }

  img.pixelated {
    image-rendering: pixelated;
  }

  code {
    padding: 2rem;
    border-radius: 20px;
  }

  h2 {
    &::before {
      content: counter(h2counter, upper-roman) '.\0000a0\0000a0\0000a0';
      counter-increment: h2counter;
      counter-reset: h3counter;
    }
  }

  counter-reset: h2counter;
  h1 {
    counter-reset: h2counter;
  }

  span.red {
    color: rgb(255 * 0.98824, 255 * 0.38431, 255 * 0.33333);
  }

  span.blue {
    color: rgb(255 * 0.3451, 255 * 0.76863, 255 * 0.86667);
  }

  span.green {
    color: rgb(255 * 0.51373, 255 * 0.75686, 255 * 0.40392);
  }

  .side {
    width: 55%;
  }

  p.note {
    margin: 2rem 1rem;
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }
}

.katex {
  font-size: 1rem !important;
}

button {
  position: relative;

  &::before {
    background-color: black;
    border-radius: 10px;
    color: #fff;
    content: attr(data-tooltip);
    display: block;
    font-family: sans-serif;
    font-size: 14px;
    padding: 10px 20px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition-duration: 200ms;
  }

  &:hover::before {
    opacity: 1;
  }
}
